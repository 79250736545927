(function($) {
  Drupal.behaviors.spp_howtouse_v1 = {
    attach: function(context, settings) {
      var qsData;
      var $btn_quickshop;
      var quickshopData;
      var quickshopPID;

      // quickshop on how to use
      if (page_data?.['spp-howto']) {
        qsData = page_data['spp-howto'].products;
        $btn_quickshop = $('.js-quickshop-add-button', context);
        $btn_quickshop.off('click').on('click', function(e) {
          e.preventDefault();
          quickshopPID = $(this).attr('data-productid');
          quickshopData = qsData.find((pr) => (pr.PRODUCT_ID === quickshopPID));
          site.quickshop(quickshopData);

          if (window.parentIFrame) {
            quickshopData['_elc_action'] = 'scrollIntoView';
            quickshopData.scrollY = 450;
            window.parentIFrame.sendMessage(quickshopData, '*');
          }
        });
      }
    } // attach
  };

})(jQuery);
